import endpoints from '../../ruutsApi/endpoints'
import updateApiData from '../../ruutsApi/updateApiData'

export const updateMonitoringSite = async (site, token) => {
  try {
    const siteBody = {
      id: site.id,
      data: {
        ...site,
      },
    }
    return await updateApiData(`${endpoints.sites}`, siteBody, token)
  } catch (error) {
    console.error(error)
    throw new Error('Unable to update site')
  }
}
