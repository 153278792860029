import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import { Button } from '../Button/Button'
import PerimeterToValidate from './PerimeterToValidate'

const PerimeterValidated = () => {
  return <Typography variant="p">El perímetro ya se encuentra validado.</Typography>
}

const PerimeterValidationPanel = ({
  overrideEdition,
  canValidate,
  isPerimeterValidated,
  setMapActions,
  setFeatures,
  featuresUpdated,
  setHighlightedPoint,
  mapReady,
}) => {
  const [confirmStartProcess, setConfirmStartProcess] = useState(false)
  const { setConfirmationModalConfig } = useContext(AppContext)

  const handleConfirmStartProcess = useCallback(() => {
    setConfirmationModalConfig({
      open: true,
      title: 'Atención: Proceso de validación de perímetro',
      message:
        'Está a punto de iniciar la validación del perímetro. Le informamos que cualquier modificación en las dimensiones del perímetro impactará en todos los lotes y estratos generados. ¿Desea continuar?',
      confirmLabel: 'Continuar',
      cancelLabel: 'Cancelar',
      confirmAction: () => {
        setConfirmStartProcess(true)
      },
    })
  }, [setConfirmationModalConfig, setConfirmStartProcess])

  useEffect(() => {
    const validateProcess = () => {
      if (overrideEdition) {
        setConfirmStartProcess(false)
        return
      }
      if (canValidate === null) return
      if (!canValidate.value) {
        setConfirmStartProcess(true)
        return
      }
      handleConfirmStartProcess()
    }
    validateProcess()
  }, [canValidate, handleConfirmStartProcess, isPerimeterValidated, overrideEdition])

  return (
    <>
      {canValidate && (
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6">Validación de perímetro</Typography>
          {confirmStartProcess ? (
            <>
              {isPerimeterValidated && !overrideEdition ? (
                <PerimeterValidated />
              ) : (
                <PerimeterToValidate
                  canValidate={canValidate}
                  featuresUpdated={featuresUpdated}
                  isPerimeterValidated={isPerimeterValidated}
                  mapReady={mapReady}
                  overrideEdition={overrideEdition}
                  setFeatures={setFeatures}
                  setHighlightedPoint={setHighlightedPoint}
                  setMapActions={setMapActions}
                />
              )}
            </>
          ) : (
            <Button onClick={handleConfirmStartProcess}>Iniciar proceso</Button>
          )}
        </Box>
      )}
    </>
  )
}

export default PerimeterValidationPanel
