/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { useAuth0 } from '@auth0/auth0-react'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ReplayIcon from '@mui/icons-material/Replay'
import LoadingButton from '@mui/lab/LoadingButton'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import markerIconPngBlue from 'leaflet/dist/images/marker-icon.png'
import { useEffect, useMemo, useState } from 'react'
import { useUserRole } from '../../hooks/useUserRole'
import endpoints from '../../services/ruutsApi/endpoints'
import postApiData from '../../services/ruutsApi/postApiData'
import { GeoJSON } from '../../utils/GeoJSON/index'
import randomPointsInPolygonV2 from '../../utils/randomizePointsV2'
import ReRandomizeModal from './ReRandomizeModal'

const SamplePlanningPanel = ({
  farm,
  stratas,
  wasSaved,
  setWasSaved,
  monitoringSitesBySamplingAreas,
  setMonitoringSitesBySamplingAreas,
  setMonitoringSitesSaved,
  exclusionAreas,
}) => {
  const [loading, setLoading] = useState(false)
  const [strataSites, setStrataSites] = useState({})
  const [openReRandomizeModal, setOpenReRandomizeModal] = useState(false)
  const [reRandomizeIndex, setReRandomizeIndex] = useState()
  const [reRandomizeStrata, setReRandomizeStrata] = useState()
  const [exclusionTotalArea, setExclusionTotalArea] = useState(0)
  const [samplingTotalArea, setSamplingTotalArea] = useState(0)
  const [randomSitesExist, setRandomSitesExist] = useState(false)
  const { isViewOnly } = useUserRole()

  const { getAccessTokenSilently } = useAuth0()

  const randomizeMonitoringSites = () => {
    setLoading(!loading)

    const randomMonitoringSites = {}
    stratas.features.forEach(strata => {
      randomMonitoringSites[strata.properties.name] = randomPointsInPolygonV2(
        strataSites[strata.properties.name],
        strata,
        [],
      )
    })
    Object.keys(randomMonitoringSites).forEach(strata => {
      randomMonitoringSites[strata].forEach(site => {
        site.properties.randomizeCounter = 0
        site.properties.randomizeReason = []
        site.properties.isRandomSite = true
      })
    })
    setMonitoringSitesBySamplingAreas(prev => {
      const placedSites = {}
      Object.keys(prev).forEach(strata => {
        placedSites[strata] = prev[strata].filter(site => !site.properties.isRandomSite)
      })

      const newMonitoringSites = {}
      // if placed sites has sites
      if (Object.keys(placedSites).length > 0) {
        stratas.features.forEach(strata => {
          if (placedSites[strata.properties.name].length > 0) {
            newMonitoringSites[strata.properties.name] = [
              ...placedSites[strata.properties.name],
              ...randomMonitoringSites[strata.properties.name],
            ]
          } else {
            newMonitoringSites[strata.properties.name] =
              randomMonitoringSites[strata.properties.name]
          }
        })
        return newMonitoringSites
      }
      stratas.features.forEach(strata => {
        newMonitoringSites[strata.properties.name] = randomMonitoringSites[strata.properties.name]
      })
      return newMonitoringSites
    })
    setRandomSitesExist(true)
  }

  const handleCloseReRandomizeModal = () => {
    setOpenReRandomizeModal(false)
  }

  const reRandomizeMonitoringSite = (strata, index, reason) => {
    if (strata && index !== undefined) {
      setMonitoringSitesBySamplingAreas(prev => {
        const newMonitoringSites = { ...prev }
        const placedSites = {}
        Object.keys(prev).forEach(strata => {
          placedSites[strata] = prev[strata].filter(site => !site.properties.isRandomSite)
        })
        const randomSites = {}
        Object.keys(prev).forEach(strata => {
          randomSites[strata] = prev[strata].filter(site => site.properties.isRandomSite)
        })

        const oldSite = randomSites[strata.properties.name][index]
        oldSite.properties.randomizeReason.push(reason)
        randomSites[strata.properties.name].splice(index, 1)
        const newMonitoringSitesStrata = randomPointsInPolygonV2(
          strataSites[strata.properties.name],
          strata,
          randomSites[strata.properties.name],
          index,
        )

        newMonitoringSitesStrata[index].properties = oldSite.properties
        newMonitoringSitesStrata[index].properties.randomizeCounter++

        newMonitoringSites[strata.properties.name] = [
          ...placedSites[strata.properties.name],
          ...newMonitoringSitesStrata,
        ]
        return newMonitoringSites
      })
    }
  }

  const handleConfirmReRandomizeModal = (strata, index, reason) => {
    setOpenReRandomizeModal(false)
    reRandomizeMonitoringSite(strata, index, reason)
  }

  const saveSamplePlanning = async () => {
    const token = await getAccessTokenSilently()
    const body = []

    Object.keys(monitoringSitesBySamplingAreas).forEach(strata => {
      monitoringSitesBySamplingAreas[strata].forEach(site => {
        // if site exist in previous sites, skip
        if (site.properties.id) {
          return
        }
        body.push({
          farmId: farm.id,
          name: site.properties.name,
          samplingAreaId: stratas.features.filter(s => s.properties.name === strata)?.[0]
            ?.properties?.id,
          plannedLocation: [site.geometry.coordinates[1], site.geometry.coordinates?.[0] || 0],
          randomizeCounter: site.properties.randomizeCounter,
          randomizeReason: site.properties.randomizeReason,
          isRandomSite: site.properties.isRandomSite || false,
        })
      })
    })

    for (let i = 0; i < body.length; i++) {
      try {
        await postApiData(endpoints.sites, body[i], token)
        setWasSaved(true)
        setMonitoringSitesSaved(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [loading])

  const filteredStrataFeatures = useMemo(() => {
    if (!stratas?.length) return []
    return stratas.features.filter(strata => strata.properties.belongToFarm)
  }, [stratas])

  useEffect(() => {
    const strataSites = filteredStrataFeatures.reduce((acc, strata) => {
      acc[strata.properties.name] = 0
      return acc
    }, {})

    let samplingTotalArea = 0
    if (filteredStrataFeatures.length) {
      samplingTotalArea =
        filteredStrataFeatures.reduce((acc, strata) => {
          const area = strata.geometry?.coordinates?.length > 0 ? GeoJSON.area(strata) : 0
          return acc + area
        }, 0) / 10000
    }

    setStrataSites(strataSites)
    setSamplingTotalArea(samplingTotalArea.toFixed(2))
  }, [filteredStrataFeatures])

  useEffect(() => {
    if (exclusionAreas.length) {
      setExclusionTotalArea(() => {
        let eTA = 0
        exclusionAreas.forEach(exclusionArea => {
          eTA += GeoJSON.hectareArea(exclusionArea.toGeoJSON)
        })
        return eTA.toFixed(2)
      })
    }
  }, [exclusionAreas])

  useEffect(() => {
    if (!filteredStrataFeatures?.length || !monitoringSitesBySamplingAreas?.length) return

    filteredStrataFeatures.forEach(strata => {
      const { name } = strata.properties
      const randomSiteExist = monitoringSitesBySamplingAreas[name]?.some(
        site => site.properties.isRandomSite,
      )

      setRandomSitesExist(randomSiteExist)
    })
  }, [filteredStrataFeatures, monitoringSitesBySamplingAreas])

  const checkValue = name => {
    if (strataSites[name] < 4 || strataSites[name] > 30) {
      return 'El número de sitios debe estar entre 4 y 30'
    }
    return ''
  }

  return (
    <>
      <ReRandomizeModal
        handleCloseReRandomizeModal={handleCloseReRandomizeModal}
        handleConfirmReRandomizeModal={handleConfirmReRandomizeModal}
        index={reRandomizeIndex}
        open={openReRandomizeModal}
        strata={reRandomizeStrata}
      />

      <Stack sx={{ height: '75vh', overflowY: 'scroll' }}>
        <Box>
          {stratas &&
            stratas.features
              .sort((a, b) => a.properties.name.localeCompare(b.properties.name))
              .map((strata, index) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} size="small">
                      <Grid
                        container
                        sx={{ alignItems: 'center', padding: '0 20px', textAlign: 'center' }}
                      >
                        <Grid item xs={4}>
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: strata.properties.color,
                              }}
                            />
                            <Typography sx={{ textAlign: 'left' }} variant="p">
                              {strata.properties.name}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          {strata.properties.belongToFarm && (
                            <>
                              <Typography variant="h6">
                                <b>
                                  {(
                                    (strata.properties.area /
                                      (GeoJSON.hectareArea(farm.toGeoJSON) - exclusionTotalArea)) *
                                    100
                                  ).toFixed(1)}
                                  %
                                </b>
                              </Typography>
                              <Typography variant="p">{strata.properties.area} has </Typography>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {!wasSaved && strata.properties.belongToFarm && (
                            <TextField
                              required
                              autoComplete="off"
                              disabled={isViewOnly}
                              error={Boolean(checkValue(strata.properties.name))}
                              helperText={checkValue(strata.properties.name)}
                              id="sample-amount"
                              label="Nº de Sitios"
                              placeholder="ej. 5"
                              slotProps={{
                                htmlInput: { min: 4, max: 30, step: 1 },
                              }}
                              sx={{ width: '100%' }}
                              type="number"
                              onChange={e => {
                                setStrataSites(sS => {
                                  return { ...sS, [strata.properties.name]: e.target.value }
                                })
                              }}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            />
                          )}
                          {wasSaved && (
                            <Typography variant="p">
                              {monitoringSitesBySamplingAreas[strata.properties.name].length} sitios
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: '#eee' }}>
                      {monitoringSitesBySamplingAreas[strata.properties.name] &&
                        monitoringSitesBySamplingAreas[strata.properties.name].filter(
                          site => site.properties.isRandomSite,
                        )?.length > 0 && (
                          <Box
                            sx={{
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6">
                              <b>
                                Sitios Aleatorios (
                                {
                                  monitoringSitesBySamplingAreas[strata.properties.name].filter(
                                    site => site.properties.isRandomSite,
                                  )?.length
                                }
                                )
                              </b>
                            </Typography>

                            <Grid
                              container
                              sx={{
                                alignItems: 'center',
                                padding: '5px 20px',

                                textAlign: 'center',
                              }}
                            >
                              <Grid item xs={1} />
                              <Grid item xs={4}>
                                <Typography variant="p">
                                  <b>Nombre del sitio</b>
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="p">
                                  <b>Coordenadas</b>
                                </Typography>
                              </Grid>
                              {!wasSaved && (
                                <Grid item xs={3}>
                                  <Typography variant="p">
                                    <b>Re-aleatorizar</b>
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                            {monitoringSitesBySamplingAreas[strata.properties.name] &&
                              monitoringSitesBySamplingAreas[strata.properties.name]
                                .filter(site => site.properties.isRandomSite)
                                .sort((a, b) => a.properties.name.localeCompare(b.properties.name))
                                .map((monitoringSite, index) => {
                                  return (
                                    <Grid
                                      key={index}
                                      container
                                      sx={{
                                        alignItems: 'center',
                                        padding: '5px 20px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Grid item sx={{ textAlign: 'right' }} xs={1}>
                                        <div
                                          style={{
                                            backgroundColor: strata.properties.color,
                                            filter: 'saturate(2.5)',
                                            borderRadius: '50%',
                                            border: '2px solid #000',
                                            boxShadow: 'rgba(0,0,0,.5) 1px 1px 3px',
                                            width: '20px',
                                            height: '20px',
                                            display: 'inline-block',
                                            transform: 'scale(0.8)',
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        {!wasSaved && (
                                          <TextField
                                            defaultValue={monitoringSite.properties.name}
                                            placeholder="Nombre del sitio"
                                            variant="standard"
                                            onChange={e => {
                                              if (e.target.value.length) {
                                                setMonitoringSitesBySamplingAreas(mS => {
                                                  const newMonitoringSites = { ...mS }
                                                  const placedSites = {}
                                                  Object.keys(mS).forEach(strata => {
                                                    placedSites[strata] = mS[strata].filter(
                                                      site => !site.properties.isRandomSite,
                                                    )
                                                  })

                                                  const randomSites = {}
                                                  Object.keys(mS).forEach(strata => {
                                                    randomSites[strata] = mS[strata].filter(
                                                      site => site.properties.isRandomSite,
                                                    )
                                                  })

                                                  randomSites[strata.properties.name][
                                                    index
                                                  ].properties.name = e.target.value

                                                  newMonitoringSites[strata.properties.name] = [
                                                    ...placedSites[strata.properties.name],
                                                    ...randomSites[strata.properties.name],
                                                  ]
                                                  return newMonitoringSites
                                                })
                                              }
                                            }}
                                          />
                                        )}
                                        {wasSaved && (
                                          <Typography variant="p">
                                            {monitoringSite.properties.name}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography variant="p">
                                          {`${
                                            monitoringSite.geometry.coordinates?.[1]?.toFixed(11) ??
                                            0
                                          }, ${monitoringSite.geometry.coordinates?.[0]?.toFixed(11) ?? 0}`}
                                        </Typography>
                                      </Grid>
                                      {!wasSaved && (
                                        <>
                                          <Grid item xs={2}>
                                            <Tooltip title="Aleatorizar sitio - Máximo 2 iteraciones">
                                              <IconButton
                                                color="primary"
                                                disabled={
                                                  monitoringSite.properties.randomizeCounter > 1
                                                }
                                                size="small"
                                                variant="contained"
                                                onClick={() => {
                                                  setReRandomizeIndex(index)
                                                  setReRandomizeStrata(strata)
                                                  setOpenReRandomizeModal(true)
                                                }}
                                              >
                                                <ReplayIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Typography sx={{ fontSize: 11 }} variant="p">
                                              {monitoringSite.properties.randomizeCounter}/2
                                            </Typography>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  )
                                })}
                          </Box>
                        )}
                      {monitoringSitesBySamplingAreas[strata.properties.name] &&
                        monitoringSitesBySamplingAreas[strata.properties.name].filter(
                          site => !site.properties.isRandomSite,
                        )?.length > 0 && (
                          <Box
                            sx={{
                              alignItems: 'center',
                              textAlign: 'center',
                              paddingTop: '20px',
                            }}
                          >
                            <Typography variant="h6">
                              <b>
                                Sitios Manuales (
                                {
                                  monitoringSitesBySamplingAreas[strata.properties.name].filter(
                                    site => !site.properties.isRandomSite,
                                  )?.length
                                }
                                )
                              </b>
                            </Typography>
                            <Grid
                              container
                              sx={{
                                alignItems: 'center',
                                padding: '10px 20px',
                                textAlign: 'center',
                              }}
                            >
                              <Grid item xs={1} />
                              <Grid item xs={4}>
                                <Typography variant="p">
                                  <b>Nombre del sitio</b>
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="p">
                                  <b>Coordenadas</b>
                                </Typography>
                              </Grid>
                            </Grid>
                            {monitoringSitesBySamplingAreas[strata.properties.name] &&
                              monitoringSitesBySamplingAreas[strata.properties.name]
                                .filter(site => !site.properties.isRandomSite)
                                .sort((a, b) => a.properties.name.localeCompare(b.properties.name))
                                .map((monitoringSite, index) => {
                                  return (
                                    <Grid
                                      key={index}
                                      container
                                      sx={{
                                        alignItems: 'center',
                                        padding: '5px 20px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Grid item sx={{ textAlign: 'right' }} xs={1}>
                                        <img alt="" src={markerIconPngBlue} width="20px" />
                                      </Grid>
                                      <Grid item xs={4}>
                                        {!wasSaved && (
                                          <TextField
                                            defaultValue={monitoringSite.properties.name}
                                            placeholder="Nombre del sitio"
                                            variant="standard"
                                            onChange={e => {
                                              setMonitoringSitesBySamplingAreas(mS => {
                                                const newMonitoringSites = { ...mS }
                                                newMonitoringSites[strata.properties.name][
                                                  index
                                                ].properties.name = e.target.value
                                                return newMonitoringSites
                                              })
                                            }}
                                          />
                                        )}
                                        {wasSaved && (
                                          <Typography variant="p">
                                            {monitoringSite.properties.name}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography variant="p">
                                          {`${
                                            monitoringSite.geometry.coordinates?.[1]?.toFixed(11) ??
                                            0
                                          }, ${monitoringSite.geometry.coordinates?.[0]?.toFixed(11) ?? 0}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )
                                })}
                          </Box>
                        )}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
        </Box>
        {samplingTotalArea && farm && (
          <Stack
            direction="column"
            mt={2}
            spacing={2}
            sx={{ width: '100%', textAlign: 'center', color: 'text.secondary' }}
          >
            <Typography variant="p">
              Área de Monitoreo total: <b>{samplingTotalArea} has </b>
              <i>{((samplingTotalArea / GeoJSON.hectareArea(farm.toGeoJSON)) * 100).toFixed(2)}%</i>
            </Typography>
            <Typography variant="p">
              Área de Exclusión total: <b>{exclusionTotalArea > 0 ? exclusionTotalArea : 0} has </b>
              <i>
                {(
                  ((exclusionTotalArea > 0 ? exclusionTotalArea : 0) /
                    GeoJSON.hectareArea(farm.toGeoJSON)) *
                  100
                ).toFixed(2)}
                %
              </i>
            </Typography>
            <Typography variant="p">
              Área no asignada:{' '}
              <b>
                {(
                  GeoJSON.hectareArea(farm.toGeoJSON) -
                  samplingTotalArea -
                  (exclusionTotalArea > 0 ? exclusionTotalArea : 0)
                ).toFixed(2)}{' '}
                has{' '}
              </b>
              <i>
                {(
                  ((GeoJSON.hectareArea(farm.toGeoJSON) -
                    samplingTotalArea -
                    (exclusionTotalArea > 0 ? exclusionTotalArea : 0)) /
                    GeoJSON.hectareArea(farm.toGeoJSON)) *
                  100
                ).toFixed(2)}
                %
              </i>
            </Typography>
            <Typography variant="p">
              Área Total: <b>{GeoJSON.hectareArea(farm.toGeoJSON).toFixed(2)} has</b> <i>100%</i>
            </Typography>
          </Stack>
        )}
      </Stack>
      {stratas && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disabled={
              Object.keys(strataSites).some(siteName => checkValue(siteName)) ||
              wasSaved ||
              isViewOnly
            }
            endIcon={<AddLocationIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={randomizeMonitoringSites}
          >
            Aleatorizar Sitios
          </LoadingButton>
        </Box>
      )}
      {stratas && !wasSaved && randomSitesExist && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            color="primary"
            disabled={isViewOnly}
            endIcon={<CheckCircleIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={saveSamplePlanning}
          >
            Guardar Plan de Monitoreo
          </LoadingButton>
        </Box>
      )}
      {stratas && wasSaved && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <Typography variant="p">¡Plan de Monitoreo Guardado!</Typography>
        </Box>
      )}
    </>
  )
}

export default SamplePlanningPanel
