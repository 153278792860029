import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import ClearIcon from '@mui/icons-material/Clear'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import * as turf from '@turf/turf'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  FeatureGroup,
  GeoJSON as GeoJSONLeflet,
  LayerGroup,
  LayersControl,
  MapContainer,
  Pane,
} from 'react-leaflet'
import { GeomanControls } from 'react-leaflet-geoman-v2'
import { v4 as uuidV4 } from 'uuid'
import AppContext from '../../context/appContext'
import { GeoJSON } from '../../utils/GeoJSON/index'
import {
  checkIntersectionWithExclusionAreas,
  checkIntersectionWithOtherFeatures,
  checkIntersectionWithPerimeter,
  getRandomColor,
  processIntersectionWithPerimeter,
} from '../../utils/mapDrawHandle'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError.js'
import BaseLayers from '../map/BaseLayers'

const ClassificationMap = ({
  perimeter,
  samplingAreas,
  samplingAreasFeatures,
  setSamplingAreasFeatures,
  exclusionAreasFeatures,
  setExclusionAreasFeatures,
  mapWidth,
  mapHeight,
  mapAction,
  setMapAction,
  selectedAreaType,
  selectedArea,
  wasSaved,
  vectorized,
  allowSelfIntersection,
  layers = [],
  handleMapBoundsChanged,
}) => {
  const [map, setMap] = useState()
  const [mapLoading, setMapLoading] = useState(false)
  const [center, setCenter] = useState([-35.329970415341656, -65.12262446073629])
  const [mapUpdated, setMapUpdated] = useState(0)
  const featureGroupRef = useRef()
  const { setPartialChanges } = useContext(AppContext)
  const [isEditionMode, setIsEditionMode] = useState(false)
  const { handleError } = useCustomSnackbarError()

  const cancelEdition = useCallback(() => {
    setMapAction(null)
  }, [setMapAction])

  const handleKeyEvent = e => {
    if (e.event.type === 'keyup' && e.event.key === 'Escape') {
      cancelEdition()
    }
  }

  // init map, set perimeter & bounds
  useEffect(() => {
    try {
      if (map && perimeter?.geometry?.coordinates?.length > 0) {
        setCenter(turf.center(perimeter))
        map.fitBounds(L.geoJSON(perimeter).getBounds())
        if (handleMapBoundsChanged) {
          handleMapBoundsChanged(map.getBounds())
        }
      }
    } catch (error) {
      handleError(error)
    }
  }, [handleError, handleMapBoundsChanged, map, perimeter])

  useEffect(() => {
    try {
      setMapLoading(true)
      if (samplingAreasFeatures && exclusionAreasFeatures) {
        let newFeatures = []
        samplingAreasFeatures.forEach(strata => {
          let s = checkIntersectionWithPerimeter(strata, perimeter)
          const newExclusionAreasFeatures = map?.exclusionAreasFeatures || exclusionAreasFeatures
          s = checkIntersectionWithExclusionAreas(s, newExclusionAreasFeatures)
          if (!s) {
            return samplingAreasFeatures
          }

          newFeatures = checkIntersectionWithOtherFeatures(s, newFeatures)
          s.properties = strata.properties
          s.properties.area = GeoJSON.hectareArea(s)
          newFeatures = [...newFeatures, s]
          return newFeatures
        })
        setSamplingAreasFeatures(newFeatures)
      }
    } catch (error) {
      handleError(error)
    } finally {
      setMapUpdated(prev => prev + 1)
    }
  }, [
    wasSaved,
    samplingAreas,
    vectorized,
    samplingAreasFeatures,
    exclusionAreasFeatures,
    setSamplingAreasFeatures,
    perimeter,
    map?.exclusionAreasFeatures,
    handleError,
  ])

  const createStrataOnMap = useCallback(
    (strata, name, color) => {
      const strataInsidePerimeter = processIntersectionWithPerimeter(strata, perimeter)
      if (!strataInsidePerimeter) return

      L.geoJSON(strataInsidePerimeter, {
        style: {
          fillColor: color,
          color: strata.properties.selected ? 'yellow' : color,
          weight: strata.properties.selected ? 4 : 2,
          opacity: 1,
          fillOpacity: 0.15,
        },
      })
        .bindTooltip(name)
        .addTo(map)
    },
    [map, perimeter],
  )

  const createExclusionOnMap = useCallback(
    (exclusion, name) => {
      const exclusionInsidePerimeter = processIntersectionWithPerimeter(exclusion, perimeter)
      if (!exclusionInsidePerimeter) return

      L.geoJSON(exclusionInsidePerimeter, {
        style: {
          fillColor: exclusion.properties.color || 'black',
          color: exclusion.properties.selected ? 'yellow' : 'black',
          weight: exclusion.properties.selected ? 4 : 2,
          opacity: 1,
          fillOpacity: 0.4,
        },
      })
        .bindTooltip(name)
        .addTo(map)
    },
    [map, perimeter],
  )

  // on map update
  useEffect(() => {
    try {
      if (!map || !perimeter) return

      setMapLoading(true)
      // Remove all layers from map except base layers and fixed pane layers
      const layerNames = layers.map(layer => layer.name)
      const fixedPanesName = [...layerNames, 'perimeterPane']
      map.eachLayer(layer => {
        if (!layer._url && !fixedPanesName.includes(layer.options?.pane)) {
          map.removeLayer(layer)
        }
      })

      // add samplingAreasFeatures
      samplingAreasFeatures?.forEach(strata => {
        if (strata.geometry.type === 'MultiPolygon') {
          strata.geometry.coordinates.forEach(polygon => {
            const feature = turf.polygon(polygon)
            if (GeoJSON.area(feature)) {
              feature.properties = strata.properties
              createStrataOnMap(feature, strata.properties.name, strata.properties.color)
            }
          })
        } else {
          createStrataOnMap(strata, strata.properties.name, strata.properties.color)
        }
      })

      // add exclusion areas
      exclusionAreasFeatures?.forEach(exclusion => {
        if (exclusion.geometry.type === 'MultiPolygon') {
          exclusion.geometry.coordinates.forEach(polygon => {
            const feature = turf.polygon(polygon)
            if (GeoJSON.area(feature)) {
              feature.properties = exclusion.properties
              createExclusionOnMap(feature, exclusion.properties.name)
            }
          })
        } else {
          createExclusionOnMap(exclusion, exclusion.properties.name)
        }
      })

      map.exclusionAreasFeatures = exclusionAreasFeatures
    } catch (error) {
      handleError(error)
    }
    setMapLoading(false)
  }, [mapUpdated, samplingAreasFeatures, exclusionAreasFeatures, map, perimeter, handleError])

  const getNewStrataName = prev => {
    // find max number in strata names
    let max = 0
    prev.forEach(strata => {
      const num = parseInt(strata.properties.name.split(' ')[1], 10)
      if (num > max) {
        max = num
      }
    })
    return `Estrato ${max + 1}`
  }

  const handleCreate = async layer => {
    setMapLoading(true)
    try {
      const newFeature = layer.toGeoJSON()
      if (!checkIntersectionWithPerimeter(newFeature, perimeter)) {
        cancelEdition()
        setPartialChanges(true)
        return
      }

      if (map.selectedArea) {
        let union = turf.union(newFeature, map.selectedArea)

        if (map.selectedAreaType === 'strata') {
          setSamplingAreasFeatures(prev => {
            // find index of selected area by its name
            const i = prev.findIndex(
              area => area.properties.name === map.selectedArea.properties.name,
            )
            union = checkIntersectionWithPerimeter(union, perimeter)
            if (map.exclusionAreasFeatures) {
              union = checkIntersectionWithExclusionAreas(union, map.exclusionAreasFeatures)
            }
            const newFeatures = checkIntersectionWithOtherFeatures(union, prev)

            union.properties = map.selectedArea.properties
            union.properties.area = GeoJSON.hectareArea(union)

            newFeatures.splice(i, 1, union)
            return newFeatures
          })
        }

        if (map.selectedAreaType === 'exclusion') {
          setExclusionAreasFeatures(prev => {
            const i = prev.indexOf(map.selectedArea)

            union = checkIntersectionWithPerimeter(union, perimeter)
            if (setSamplingAreasFeatures) {
              setSamplingAreasFeatures(sAF => {
                const newSamplingAreas = checkIntersectionWithOtherFeatures(union, sAF)
                return newSamplingAreas
              })
            }

            const newFeatures = checkIntersectionWithOtherFeatures(union, prev)

            union.properties = map.selectedArea.properties
            union.properties.area = GeoJSON.hectareArea(union)

            newFeatures.splice(i, 1, union)
            map.exclusionAreasFeatures = newFeatures
            return newFeatures
          })
        }
      } else {
        let newArea = turf.polygon(newFeature.geometry.coordinates)

        if (map.selectedAreaType === 'strata') {
          setSamplingAreasFeatures(prev => {
            newArea = checkIntersectionWithPerimeter(newArea, perimeter)
            if (map.exclusionAreasFeatures) {
              newArea = checkIntersectionWithExclusionAreas(newArea, map.exclusionAreasFeatures)
              if (!newArea) {
                return prev
              }
            }
            const newFeatures = checkIntersectionWithOtherFeatures(newArea, prev)

            newArea.properties = {
              area: GeoJSON.hectareArea(newArea),
              color: getRandomColor(),
              name: getNewStrataName(prev),
            }
            return [...newFeatures, newArea]
          })
        }
        if (map.selectedAreaType === 'exclusion') {
          setExclusionAreasFeatures(prev => {
            newArea = checkIntersectionWithPerimeter(newArea, perimeter)
            if (setSamplingAreasFeatures) {
              setSamplingAreasFeatures(sAF => {
                const newSamplingAreas = checkIntersectionWithOtherFeatures(newArea, sAF)
                return newSamplingAreas
              })
            }
            const newFeatures = checkIntersectionWithOtherFeatures(newArea, prev)

            newArea.properties = {
              area: GeoJSON.hectareArea(newArea),
              name: '',
              color: 'black',
              exclusionAreaTypeId: '',
              hasGrazingManagement: false,
              tmpId: uuidV4(),
            }

            map.exclusionAreasFeatures = [...newFeatures, newArea]
            return [...newFeatures, newArea]
          })
        }
      }
    } catch (error) {
      handleError(error)
    }
    cancelEdition()
    setMapUpdated(prev => prev + 1)
    setPartialChanges(true)
  }

  const handleEdit = useCallback(
    async (layersToEdit, selectedAreaToEdit) => {
      setMapLoading(true)
      if (layersToEdit.length === 1) {
        let edited = layersToEdit[0].toGeoJSON()

        if (map.selectedAreaType === 'strata') {
          setSamplingAreasFeatures(prev => {
            let prevData = structuredClone(prev)
            const i = prevData.indexOf(map.selectedArea)
            prevData = prev.filter(strata => strata !== map.selectedArea)
            edited = checkIntersectionWithPerimeter(edited, perimeter)
            if (map.exclusionAreasFeatures) {
              edited = checkIntersectionWithExclusionAreas(edited, map.exclusionAreasFeatures)
            }
            const newFeatures = checkIntersectionWithOtherFeatures(edited, prevData)

            edited.properties = selectedAreaToEdit.properties
            edited.properties.area = GeoJSON.hectareArea(edited)

            newFeatures.splice(i, 0, edited)
            return newFeatures
          })
        }

        if (map.selectedAreaType === 'exclusion') {
          setExclusionAreasFeatures(prev => {
            let prevData = structuredClone(prev)
            const i = prevData.indexOf(map.selectedArea)
            prevData = prev.filter(strata => strata !== map.selectedArea)
            edited = checkIntersectionWithPerimeter(edited, perimeter)
            if (setSamplingAreasFeatures) {
              setSamplingAreasFeatures(sAF => {
                const newSamplingAreas = checkIntersectionWithOtherFeatures(edited, sAF)
                return newSamplingAreas
              })
            }
            const newFeatures = checkIntersectionWithOtherFeatures(edited, prevData)

            edited.properties = selectedAreaToEdit.properties
            edited.properties.area = GeoJSON.hectareArea(edited)

            newFeatures.splice(i, 0, edited)

            map.exclusionAreasFeatures = newFeatures
            return newFeatures
          })
        }
      } else {
        const coordinates = []

        layersToEdit.forEach(layer => {
          coordinates.push(layer.toGeoJSON().geometry.coordinates)
        })

        let edited = turf.multiPolygon(coordinates)

        if (map.selectedAreaType === 'strata') {
          setSamplingAreasFeatures(prev => {
            let prevData = structuredClone(prev)
            const i = prevData.indexOf(map.selectedArea)
            prevData = prev.filter(strata => strata !== map.selectedArea)
            edited = checkIntersectionWithPerimeter(edited, perimeter)
            if (map.exclusionAreasFeatures) {
              edited = checkIntersectionWithExclusionAreas(edited, map.exclusionAreasFeatures)
            }
            const newFeatures = checkIntersectionWithOtherFeatures(edited, prevData)

            edited.properties = selectedAreaToEdit.properties
            edited.properties.area = GeoJSON.hectareArea(edited)

            newFeatures.splice(i, 0, edited)
            return newFeatures
          })
        }

        if (map.selectedAreaType === 'exclusion') {
          setExclusionAreasFeatures(prev => {
            let prevData = structuredClone(prev)
            const i = prevData.indexOf(map.selectedArea)
            prevData = prev.filter(strata => strata !== map.selectedArea)
            edited = checkIntersectionWithPerimeter(edited, perimeter)
            if (setSamplingAreasFeatures) {
              setSamplingAreasFeatures(sAF => {
                const newSamplingAreas = checkIntersectionWithOtherFeatures(edited, sAF)
                return newSamplingAreas
              })
            }
            const newFeatures = checkIntersectionWithOtherFeatures(edited, prevData)

            edited.properties = selectedAreaToEdit.properties
            edited.properties.area = GeoJSON.hectareArea(edited)

            newFeatures.splice(i, 0, edited)
            map.exclusionAreasFeatures = newFeatures
            return newFeatures
          })
        }
      }

      cancelEdition()
      setMapUpdated(prev => prev + 1)
    },
    [cancelEdition, map, perimeter, setExclusionAreasFeatures, setSamplingAreasFeatures],
  )

  const findLayersBySelectedArea = useCallback(
    area => {
      if (!area) {
        return null
      }

      const polygons = []
      if (area.geometry.type === 'Polygon') {
        polygons.push(area)
      }
      if (area.geometry.type === 'MultiPolygon') {
        area.geometry.coordinates.forEach(poly => {
          const geoJSON = turf.polygon(poly)
          geoJSON.properties = area.properties
          polygons.push(geoJSON)
        })
      }

      const layersBySelectedArea = []
      map.eachLayer(l => {
        if (l._url) return
        if (l instanceof L.Polygon) {
          const { id, tmpId } = l.toGeoJSON().properties
          polygons.forEach(polygon => {
            if (
              (id && id === polygon.properties.id) ||
              (tmpId && tmpId === polygon.properties.tmpId)
            ) {
              layersBySelectedArea.push(l)
            }
          })
        }
      })
      return layersBySelectedArea
    },
    [map],
  )

  useEffect(() => {
    try {
      if (!map) return

      setIsEditionMode(['draw', 'edit'].includes(mapAction))
      map.selectedArea = selectedArea
      map.selectedAreaType = selectedAreaType
      const selectedLayers = findLayersBySelectedArea(selectedArea)

      switch (mapAction) {
        case 'draw':
          map.pm.enableDraw('Polygon', {})
          break
        case 'edit':
          selectedLayers?.forEach(layer => {
            layer.pm.enable({
              allowSelfIntersection,
              snappable: true,
            })
          })
          break
        case 'remove':
          setMapUpdated(prev => prev + 1)
          break
        case 'done':
          selectedLayers.forEach(layer => {
            layer.pm.disable()
          })
          handleEdit(selectedLayers, selectedArea)
          break
        case null:
          map.pm.disableDraw('Polygon')
          selectedLayers?.forEach(layer => {
            layer.pm.disable()
          })
          setMapUpdated(prev => prev + 1)
          break
        default:
          break
      }
      const displayCustomControls = ['draw', 'edit'].includes(mapAction)
      map.pm.addControls({ customControls: displayCustomControls })
      // If the map action is canceled, we don't need to flag partial changes
      if (mapAction != null) {
        setPartialChanges(true)
      }
    } catch (error) {
      handleError(error)
    }
  }, [
    allowSelfIntersection,
    findLayersBySelectedArea,
    handleEdit,
    handleError,
    map,
    mapAction,
    selectedArea,
    selectedAreaType,
    setPartialChanges,
  ])

  const getColor = layer => {
    if (layer.name.includes('Loteo')) {
      return getRandomColor()
    }

    return layer.features[0]?.properties?.color
      ? layer.features[0]?.properties?.color
      : getRandomColor()
  }

  return (
    <>
      {perimeter && (
        <Container disableGutters maxWidth={false}>
          {isEditionMode && (
            <Box
              style={{
                position: 'absolute',
                top: '20px',
                zIndex: '1000',
                transform: 'translateX(50%)',
                left: '50%',
              }}
              textAlign="center"
            >
              <Button
                color="secondary"
                endIcon={<ClearIcon />}
                style={{ margin: 'auto' }}
                variant="contained"
                onClick={() => {
                  cancelEdition()
                }}
              >
                Cancelar acción
              </Button>
            </Box>
          )}
          {mapLoading && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(255,255,255,0.4)',
                zIndex: 9999,
                width: '-webkit-fill-available',
                height: '-webkit-fill-available',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <MapContainer
            ref={setMap}
            scrollWheelZoom
            zoomControl
            attributionControl={false}
            center={center}
            style={{ height: mapHeight, width: mapWidth }}
            wheelPxPerZoomLevel={60}
            zoom={5}
            zoomDelta={1}
            zoomSnap={0.1}
          >
            {perimeter && (
              <Pane name="perimeterPane" style={{ zIndex: 300 }}>
                <FeatureGroup>
                  <LayerGroup>
                    <GeoJSONLeflet
                      data={perimeter}
                      style={_ => {
                        return {
                          color: 'black',
                          weight: 3,
                          opacity: 1,
                          fillOpacity: 0,
                        }
                      }}
                    />
                  </LayerGroup>
                </FeatureGroup>
              </Pane>
            )}

            <LayersControl collapsed={false}>
              <BaseLayers />
              {layers.map(layer => (
                <Pane key={layer.name} name={layer.name} style={{ zIndex: 301 }}>
                  <LayersControl.Overlay name={layer.name}>
                    <FeatureGroup>
                      <LayerGroup>
                        <GeoJSONLeflet
                          data={layer.features}
                          style={() => {
                            return {
                              color: getColor(layer),
                              weight: 1,
                              opacity: 1,
                              fillOpacity: 0,
                            }
                          }}
                          onEachFeature={(feature, geoLayer) => {
                            const popupContent = Object.entries(feature.properties)
                              .map(([key, value]) => {
                                // In order to set font size on the popup, an custom HTML is created
                                // since the bind popup options doesn't work
                                return `
                              <div style="display: flex; align-items: center; height: 15px">
                                <p style="font-size: 11px; font-weight: bold">${key}:</p>
                                <p style="font-size: 11px;">&nbsp;${value} </p>
                              </div>`
                              })
                              .join('')
                            geoLayer.bindPopup(popupContent)
                          }}
                        />
                      </LayerGroup>
                    </FeatureGroup>
                  </LayersControl.Overlay>
                </Pane>
              ))}
            </LayersControl>

            <FeatureGroup ref={featureGroupRef}>
              <GeomanControls
                globalOptions={{
                  continueDrawing: false,
                  snapDistance: 5,
                  allowSelfIntersection,
                  templineStyle: {
                    color: selectedArea?.properties.color || 'grey',
                  },
                  hintlineStyle: {
                    color: selectedArea?.properties.color || 'grey',
                    dashArray: [5, 5],
                    weight: 2,
                  },
                  layersToCut: featureGroupRef.current,
                  layerGroup: featureGroupRef.current,
                }}
                lang="es"
                options={{
                  position: 'topleft',
                  drawControls: false,
                  editControls: false,
                  optionsControls: false,
                  customControls: false,
                }}
                onCreate={e => handleCreate(e.layer)}
                onKeyEvent={e => handleKeyEvent(e)}
              />
            </FeatureGroup>
          </MapContainer>
        </Container>
      )}
    </>
  )
}

export default ClassificationMap
