import React, { Fragment, useContext } from 'react'

import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import DropTiff from './DropTiff'
import AutomaticClasification from './AutomaticClasification'

import AppContext from '../../context/appContext'
import { useUserRole } from '../../hooks/useUserRole'

const textsByClasificationType = new Map([
  [
    'ExclusionArea',
    {
      title: 'Área de exclusión',
      detail:
        'A continuación identificaremos las distintas áreas de exclusión correspondientes al establecimiento.',
      manualMode: {
        description: 'dibujando los polígonos correspondientes a cada área de exclusion',
      },
      semiAutomaticMode: {
        title: 'Creación Semi-Automática',
        description:
          'utilizando una imagen TIF/TIFF que contenga la información de la estratificación. Este archivo se filtrará, vectorizará y se utilizará para la creación de los estratos.',
      },
    },
  ],
  [
    'Stratification',
    {
      title: 'Estratificación',
      detail:
        'A continuación identificaremos los distintos estratos correspondientes al establecimiento.',
      manualMode: {
        description: 'dibujando los polígonos correspondientes a cada estrato.',
      },
      semiAutomaticMode: {
        title: 'Creación Semi-Automática',
        description:
          'utilizando una imagen TIF/TIFF que contenga la información de la estratificación. Este archivo se filtrará, vectorizará y se utilizará para la creación de los estratos.',
      },
      automaticMode: {
        title: 'Creación Automática',
        description:
          'Mediante el perímetro del campo se generar de forma automática la detección de las diferentes estratos.',
      },
    },
  ],
])

const ClasificationCreation = ({
  supportedCreationModes,
  type,
  perimeter,
  georaster,
  setGeoraster,
  setSamplingAreasFeatures,
  setDrawActivated,
  setVectorized,
  previewSampling,
  setPreviewSampling,
}) => {
  const { setHideNavbar } = useContext(AppContext)
  const texts = textsByClasificationType.get(type)
  const { isViewOnly } = useUserRole()

  return (
    <Box sx={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h6">{texts.title}</Typography>
      <Typography variant="p">{texts.detail}</Typography>
      <br />
      <br />
      <Typography variant="p">Se puede hacer de las siguientes maneras:</Typography>
      <br />
      <Box sx={{ textAlign: 'left' }}>
        <ol>
          {supportedCreationModes.includes('manual') && (
            <li>
              <Typography variant="p">
                <b>Manualmente</b>: {texts.manualMode.description}.
              </Typography>
            </li>
          )}
          {supportedCreationModes.includes('semi-automatic') && (
            <li>
              <Typography variant="p">
                <b>Semi-automático</b>: {texts.semiAutomaticMode.description}
              </Typography>
            </li>
          )}
          {supportedCreationModes.includes('automatic') && (
            <li>
              <Typography variant="p">
                <b>Automáticamente</b>: {texts.automaticMode.description}
              </Typography>
            </li>
          )}
        </ol>
      </Box>
      <br />
      <br />
      {supportedCreationModes.includes('manual') && !georaster && !previewSampling && (
        <>
          <Button
            color="primary"
            disabled={isViewOnly}
            endIcon={<EditIcon />}
            variant="contained"
            onClick={() => {
              setDrawActivated(true)
              setVectorized(false)
              setHideNavbar(true)
            }}
          >
            Creación manual
          </Button>
          <br />
          <br />
          <br />
        </>
      )}
      {supportedCreationModes.includes('semi-automatic') && !previewSampling && (
        <>
          <DropTiff
            georaster={georaster}
            perimeter={perimeter}
            setDrawActivated={setDrawActivated}
            setGeoraster={setGeoraster}
            setSamplingAreasFeatures={setSamplingAreasFeatures}
            setVectorized={setVectorized}
            title={texts.semiAutomaticMode.title}
          />
          <br />
          <br />
          <br />
        </>
      )}
      {supportedCreationModes.includes('automatic') && !georaster && (
        <AutomaticClasification
          perimeter={perimeter}
          previewSampling={previewSampling}
          setDrawActivated={setDrawActivated}
          setPreviewSampling={setPreviewSampling}
          setSamplingAreasFeatures={setSamplingAreasFeatures}
          setVectorized={setVectorized}
        />
      )}
    </Box>
  )
}

export const BackButton = ({ setMapAction, setDrawActivated, setWasSaved, reloadFeatures }) => {
  const { setConfirmationModalConfig, partialChanges, setPartialChanges, setHideNavbar } =
    useContext(AppContext)

  const reloadControls = () => {
    setHideNavbar(false)
    setMapAction(null)
    setDrawActivated(false)
    setWasSaved(true)
    reloadFeatures()
  }

  return (
    <Box sx={{ padding: '10px' }}>
      <Stack
        direction="row"
        sx={{ marginBottom: '10px', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => {
          if (partialChanges) {
            setConfirmationModalConfig({
              open: true,
              title: 'Cambios sin guardar',
              message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
              confirmLabel: 'Continuar',
              cancelLabel: 'Cancelar',
              confirmAction: () => {
                reloadControls()
                setPartialChanges(false)
              },
            })
          } else {
            reloadControls()
          }
        }}
      >
        <IconButton>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography sx={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="p">
          <b>Volver</b>
        </Typography>
      </Stack>
    </Box>
  )
}

export default ClasificationCreation
