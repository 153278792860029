import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Stack,
  Grid,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useAuth0 } from '@auth0/auth0-react'
import { FilePresent } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment/moment'
import AppContext from '../../context/appContext'
import { ruutsApi } from '../../services/ruutsApi/index'
import EventViewerMap from './EventViewerMap'
import ActivitySummaryTable from './ActivitySummaryTable'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation'
import SelectHubUser from './SelectHubUser'
import { allHubsId } from '../../services/farmMapping/getFarms'

const CreateEventDialog = ({ open, handleClose, getFarmData }) => {
  const steps = [
    {
      id: 0,
      label: 'Subir Archivo',
      isFirstStep: true,
      isLastStep: false,
    },
    {
      id: 1,
      label: 'Asignar actividades',
      isFirstStep: false,
      isLastStep: false,
    },
    {
      id: 2,
      label: 'Confirmar',
      isFirstStep: false,
      isLastStep: true,
    },
  ]

  const { currentFarm } = useContext(AppContext)
  const [eventData, setEventData] = useState({})
  const [eventStartDate, setEventStartDate] = useState('')
  const [eventEndDate, setEventEndDate] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false) // State to handle the loader
  const [activeStep, setActiveStep] = useState(0)
  const [monitoringSitesWithTrackPoints, setMonitoringSitesWithTrackPoints] = useState([])
  const [waypoints, setWaypoints] = useState([])
  const [uploadError, setUploadError] = useState(null)
  const [hubUsers, setHubUsers] = useState()
  const [assignedTo, setAssignedTo] = useState(null)
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()
  const { getAccessTokenSilently, user } = useAuth0()

  const handleAssignedToChange = value => {
    setAssignedTo(value)
  }

  const handleCancel = () => {
    setFile(null)
    setLoading(false)
    setUploadError(null)
    setActiveStep(0)
    setEventData(null)
    setAssignedTo(hubUsers[0].email)
    handleClose()
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await processLoadingWithConfirmation({
        confirmationTitle: 'Crear evento',
        confirmationMessage:
          '¿Se creará el evento y se confirmarán los sitios con las ubicaciones proporcionadas. ¿Desea continuar?',
        loadingMessage: 'Creando evento...',
        errorMessage: 'Error al crear el evento.',
        doAction: async ({ token, dryRun }) => {
          if (dryRun) {
            return
          }
          await ruutsApi.addMonitoringEvent({
            farmId: currentFarm.id,
            eventData,
            eventStartDate,
            eventEndDate,
            isBackdatedEvent: true,
            gpsData: file,
            siteConfirmationRequired: true,
            token,
          })
          handleCancel()
          getFarmData()
        },
      })
    } catch (error) {
      setUploadError(error)
      setLoading(false)
    }
  }

  const uploadGpxFile = async ({ gpxFile }) => {
    const token = await getAccessTokenSilently()
    return ruutsApi.compareGpsData({
      farm: currentFarm,
      token,
      file: gpxFile,
      eventStartDate,
      eventEndDate,
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/octet-stream': ['.gpx'],
      'application/gpx': ['.gpx'],
      'application/gpx+xml': ['.gpx'],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      try {
        if (acceptedFiles.length) {
          setUploadError(null)
          setLoading(true)
          setFile(acceptedFiles[0])
        }
        if (fileRejections.length) {
          setUploadError(new Error('Tipo de archivo no permitido. Usar extensión GPX'))
        }
      } catch (error) {
        setUploadError(error)
      } finally {
        setLoading(false)
        setEventData({})
      }
    },
  })

  const validateGpxFile = async () => {
    try {
      if (file) {
        setLoading(true)
        const { monitoringSitesWithResults, waypoints: waypointsResult } = await uploadGpxFile({
          gpxFile: file,
        })
        setUploadError(null)
        setMonitoringSitesWithTrackPoints(monitoringSitesWithResults)
        setWaypoints(waypointsResult)
      }
      return true
    } catch (error) {
      setUploadError(error)
      return false
    } finally {
      setLoading(false)
      setEventData({})
    }
  }

  const handleNext = async () => {
    if (activeStep === steps[0].id) {
      const isValid = await validateGpxFile()
      if (isValid) {
        setActiveStep(steps[1].id)
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const getUsersFromMyHub = useCallback(async () => {
    const token = await getAccessTokenSilently()

    const q = {
      query: `user_metadata.hubId:${currentFarm.hubId} OR user_metadata.hubId:${allHubsId}`,
    }
    const users = await ruutsApi.getApiData(ruutsApi.endpoints.users, q, token)
    users.sort((a, b) => {
      if (a.email === user.email) return -1
      if (b.email === user.email) return 1
      return 0
    })
    setHubUsers(users)
    setAssignedTo(users[0].email)
  }, [currentFarm.hubId, getAccessTokenSilently, user.email])

  useEffect(() => {
    getUsersFromMyHub()
    return () => null
  }, [currentFarm, getUsersFromMyHub])

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle>
        Crear Evento - {eventStartDate ? moment(eventStartDate).format('MMM D, YYYY') : ''}
      </DialogTitle>
      <DialogContent sx={{ height: '97vh' }}>
        <Stepper activeStep={activeStep}>
          {steps.map(step => (
            <Step key={step.id}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps[0].id && hubUsers && (
          <Grid container alignContent="center" height="80%" spacing={2}>
            <Grid item xs={12}>
              <Stack alignItems="center" mt={4} width="100%">
                <Typography variant="h6">Responsable del evento</Typography>
                <Typography variant="caption">
                  La persona responsable deberá completar el evento en la aplicación móvil (MDC)
                </Typography>

                <SelectHubUser
                  required
                  disabled={false}
                  field={{ value: assignedTo }}
                  handleChange={handleAssignedToChange}
                  hubUsers={hubUsers.map(hubUser => hubUser.email)}
                  size="medium"
                  style={{ width: '300px', pb: 2, marginTop: '20px' }}
                />

                <Typography variant="h6">Fechas del evento</Typography>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    defaultValue={moment(eventStartDate)}
                    format="DD-MM-YYYY"
                    label="Fecha de inicio"
                    sx={{ marginTop: '20px', width: '300px' }}
                    onChange={value => {
                      setEventStartDate(value.format('YYYY-MM-DD HH:mm:ssZ'))
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    defaultValue={moment(eventEndDate)}
                    format="DD-MM-YYYY"
                    label="Fecha de finalización"
                    sx={{ marginTop: '20px', width: '300px' }}
                    onChange={value => {
                      setEventEndDate(value.format('YYYY-MM-DD HH:mm:ssZ'))
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {eventStartDate && eventEndDate && (
                <Stack alignItems="center" mt={2} width="100%">
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: '2px dashed #aaa',
                      borderRadius: '10px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
                      mt: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '200px',
                      width: '300px',
                      position: 'relative',
                      textWrap: 'wrap',
                      whiteSpace: 'word-wrap',
                    }}
                  >
                    <input {...getInputProps()} />
                    {loading ? (
                      <Stack alignItems="center" spacing={2}>
                        <CircularProgress />
                        <Typography color="text.secondary">Validando archivo...</Typography>
                      </Stack>
                    ) : file && !uploadError ? (
                      <Box>
                        <Stack alignItems="center">
                          <FilePresent sx={{ fontSize: 36 }} />
                          <strong>{file.name}</strong>
                        </Stack>
                      </Box>
                    ) : uploadError ? (
                      <Typography color="error">
                        <strong>{uploadError.message}</strong>
                      </Typography>
                    ) : isDragActive ? (
                      <Typography>Arrastrar el archivo aquí...</Typography>
                    ) : (
                      <Typography>
                        Arrastrar el archivo aquí o hacer click y seleccionar uno
                      </Typography>
                    )}
                  </Box>
                  {file && !loading && (
                    <Typography mt={2}>
                      Archivo: <strong>{file.name}</strong>
                    </Typography>
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>
        )}

        {!uploadError && monitoringSitesWithTrackPoints && activeStep === steps[1].id && (
          <Box sx={{ mt: 2 }}>
            <EventViewerMap
              assignedTo={assignedTo}
              currentFarm={currentFarm}
              eventData={eventData}
              gpx={file}
              monitoringSitesWithTrackPoints={monitoringSitesWithTrackPoints}
              setEventData={setEventData}
              setMonitoringSites={setMonitoringSitesWithTrackPoints}
              setWaypoints={setWaypoints}
              waypoints={waypoints}
            />
          </Box>
        )}

        {activeStep === steps[2].id && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Revisión de actividades</Typography>
            <ActivitySummaryTable
              eventData={eventData}
              markerRefs={null}
              setEventData={setEventData}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {!steps[activeStep].isFirstStep && <Button onClick={handleBack}>Atrás</Button>}
        <Button onClick={handleCancel}>Cancelar</Button>
        {!steps[activeStep].isLastStep && (
          <Button
            disabled={
              (activeStep === steps[0].id && !file) ||
              loading ||
              !assignedTo ||
              !eventStartDate ||
              !eventEndDate
            }
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}
        {steps[activeStep].isLastStep && (
          <Button
            disabled={
              eventData == null || eventData === undefined || Object.values(eventData).length === 0
            }
            variant="contained"
            onClick={() => {
              handleSubmit()
            }}
          >
            Guardar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CreateEventDialog
