import deleteApiData from '../../ruutsApi/deleteApiData'
import endpoints from '../../ruutsApi/endpoints'

export const deleteMonitoringSite = async (siteId, token) => {
  try {
    return deleteApiData(`${endpoints.sites}/${siteId}`, null, token)
  } catch (error) {
    console.error(error)
    throw new Error('Unable to delete site')
  }
}
