import { useAuth0 } from '@auth0/auth0-react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { List, ListItem } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../context/appContext'
import { useUserRole } from '../../hooks/useUserRole.js'
import lfStore from '../../lfstore/lfStore.js'
import { dataCollectionStatementService } from '../../services/farmMapping/datacollectionStatement.js'
import { localRefData } from '../../services/localRefData/index'
import { ruutsApi } from '../../services/ruutsApi/index.js'
import { convertToCSV } from '../../utils/convertToCSV.js'
import { dataCollectionStatementTypesConventions } from '../../utils/dataCollectionStatementColors.js'
import { userRoles } from '../../utils/userRoles'
import FarmSubdivisionDocumentsDialog from '../FarmSubdivisionDocumentsDialog/FarmSubdivisionDocumentsDialog.jsx'
import { ModalTypes } from '../GlobalConfirmationModal/GlobalConfirmationModal.jsx'
import { MenuButton } from '../MenuButton/MenuButton.jsx'
import DataCollectionHomeInfo from './DataCollectionHomeInfo.jsx'
import TableActionButtons from './TableActionButtons.jsx'
import TableApproveButton from './TableApproveButton.jsx'
import TableDocumentsButtons from './TableDocumentsButtons.jsx'
import { downloadFile } from '../../utils/downloadFile.js'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading.js'
import { PROGRAM_IDS } from '../../utils/constants.js'

const dataCollectionStatementStatusType = {
  InProcess: 1,
  ToReview: 2,
  InReview: 3,
}

const ManagementDataSummary = () => {
  const navigate = useNavigate()
  const [editDocumentsDialogOpen, setEditDocumentsDialogOpen] = useState(false)
  const [usedYears, setUsedYears] = useState([])
  const [recordDataSelected, setRecordDataSelected] = useState()
  const [dataCollectionStatements, setDataCollectionStatements] = useState()
  const [dataCollectionStatementsStatus, setDataCollectionStatementsStatus] = useState([])
  const { getAccessTokenSilently } = useAuth0()
  const { isViewOnly } = useUserRole()
  const { processLoading } = useProcessLoading()
  const { userRoleSelected, setLoadingModalConfig, setConfirmationModalConfig, currentFarm } =
    useContext(AppContext)

  function handleOpen(year) {
    lfStore.setItem('dirtyForm', false)
    navigate(`/management/data/${year}`)
  }

  function handleEditDocuments(recordSelected) {
    setRecordDataSelected(recordSelected)
    setEditDocumentsDialogOpen(true)
  }

  const getDataCollectionStatements = useCallback(
    async token => {
      if (
        userRoleSelected &&
        dataCollectionStatementsStatus &&
        dataCollectionStatementsStatus.length > 0
      ) {
        const rawDataCollectionStatements = await dataCollectionStatementService.getByFarmId(
          currentFarm.id,
          userRoleSelected,
          token,
        )
        const newUsedYears = rawDataCollectionStatements.map(item => item.FarmSubdivision.year)
        const newDataCollectionStatements = rawDataCollectionStatements.map(
          dataCollectionStatement => {
            return {
              id: dataCollectionStatement.id,
              status: dataCollectionStatementsStatus.find(
                item => item.id === dataCollectionStatement.dataCollectionStatementStatusId,
              ),
              actionsAllowed: dataCollectionStatement.actionsAllowed,
              farmSubdivision: dataCollectionStatement.FarmSubdivision,
            }
          },
        )

        setUsedYears(newUsedYears)
        setDataCollectionStatements(newDataCollectionStatements)
      }
    },
    [userRoleSelected, dataCollectionStatementsStatus],
  )

  function handleApprove(dataCollectionStatement) {
    setConfirmationModalConfig({
      title: 'Confirmar aprobación',
      message:
        'Una vez aprobad la declaración no se podrá realizar ningún otro cambio. ¿Desea continuar?',
      open: true,
      confirmLabel: 'Aprobar',
      confirmAction: async () => {
        setConfirmationModalConfig({ open: false })

        try {
          const token = await getAccessTokenSilently()
          setLoadingModalConfig({
            open: true,
            dialogMessage: 'Enviando aprobación de declaración',
          })
          await dataCollectionStatementService.approve(dataCollectionStatement.id, token)
          await getDataCollectionStatements(token)
          setLoadingModalConfig({
            open: false,
            successfulMessage: 'Se aprobó la declaración',
          })
        } catch (e) {
          setLoadingModalConfig({
            open: false,
            error: e,
            errorMessage: 'Error al aprobar la declaración, por favor intente nuevamente',
          })
        }
      },
    })
  }

  const sendToReview = async dataCollectionStatement => {
    setConfirmationModalConfig({
      title: 'Confirmar envío',
      message:
        'Una vez enviado podrá seguir editando la declaración anual hasta que el Auditor comience su revisión',
      open: true,
      confirmLabel: 'Enviar',
      confirmAction: async () => {
        setConfirmationModalConfig({ open: false })

        try {
          const token = await getAccessTokenSilently()
          setLoadingModalConfig({
            open: true,
            dialogMessage: 'Enviando declaración a revisión',
          })
          await dataCollectionStatementService.sendReview(
            dataCollectionStatement.id,
            userRoleSelected,
            token,
          )
          await getDataCollectionStatements(token)
          setLoadingModalConfig({
            open: false,
            successfulMessage: 'Se envió la declaración a revisión',
          })
        } catch (e) {
          setLoadingModalConfig({
            open: false,
            error: e,
            errorMessage: 'Error al enviar la declaración a revisión, por favor intente nuevamente',
          })
        }
      },
    })
  }

  const changeOwnership = async dataCollectionStatement => {
    const message =
      userRoleSelected === userRoles.Auditor
        ? '¿Desea enviar la declaración anual al Técnico de Campo para revisar las observaciones realizadas?'
        : '¿Desea enviar la declaración anual al Auditor para revisar las modificaciones realizadas?'

    setConfirmationModalConfig({
      title: 'Confirmar envío',
      message,
      open: true,
      confirmLabel: 'Enviar',
      confirmAction: async () => {
        setConfirmationModalConfig({ open: false })

        try {
          const token = await getAccessTokenSilently()
          setLoadingModalConfig({
            open: true,
            dialogMessage: 'Enviando declaración a revisión',
          })
          await dataCollectionStatementService.changeOwnership(
            dataCollectionStatement.id,
            userRoleSelected,
            token,
          )
          await getDataCollectionStatements(token)
          setLoadingModalConfig({
            open: false,
            successfulMessage: 'Se envió la declaración a revisión',
          })
        } catch (e) {
          setLoadingModalConfig({
            open: false,
            error: e,
            errorMessage: 'Error al enviar la declaración a revisión, por favor intente nuevamente',
          })
        }
      },
    })
  }

  const handleSend = async dataCollectionStatement => {
    if (
      userRoleSelected === userRoles.FieldTechnician &&
      dataCollectionStatement?.status?.id === dataCollectionStatementStatusType.InProcess
    ) {
      await sendToReview(dataCollectionStatement)
    } else if ([userRoles.Auditor, userRoles.FieldTechnician].includes(userRoleSelected)) {
      await changeOwnership(dataCollectionStatement)
    }
  }

  const handleReview = dataCollectionStatement => {
    if (dataCollectionStatement?.status?.id === dataCollectionStatementStatusType.ToReview) {
      setConfirmationModalConfig({
        title: 'Confirmar inicio de revisión',
        message:
          'Una vez iniciada la revisión de la declaración, el técnico de campo no podrá modificarlo hasta que se lo vuelva a enviar. ¿Desea continuar?',
        open: true,
        confirmAction: async () => {
          setConfirmationModalConfig({ open: false })
          try {
            const token = await getAccessTokenSilently()
            setLoadingModalConfig({
              open: true,
              dialogMessage: 'Iniciando revisión',
            })
            await dataCollectionStatementService.startReview(
              dataCollectionStatement.id,
              userRoleSelected,
              token,
            )
            setLoadingModalConfig({
              open: false,
              successfulMessage: 'Revisión comenzada',
            })
            handleOpen(dataCollectionStatement.farmSubdivision.year)
          } catch (e) {
            setLoadingModalConfig({
              open: false,
              error: e,
              errorMessage: 'Error al comenzar la revisión, por favor intente nuevamente',
            })
          }
        },
      })
    } else {
      handleOpen(dataCollectionStatement.farmSubdivision.year)
    }
  }

  useEffect(() => {
    async function reloadDataCollectionStatements() {
      const token = await getAccessTokenSilently()
      getDataCollectionStatements(token)
    }
    reloadDataCollectionStatements()
  }, [
    userRoleSelected,
    getDataCollectionStatements,
    getAccessTokenSilently,
    dataCollectionStatementsStatus,
  ])

  useEffect(() => {
    async function loadStatus() {
      const status = await localRefData.getDataByKey(
        localRefData.keys.dataCollectionStatementStatus,
      )
      setDataCollectionStatementsStatus(status)
    }
    loadStatus()
  }, [])

  const closeDynamicFarmYearFormDialog = () => {
    setEditDocumentsDialogOpen(false)
  }

  const handleGenerateMetrics = async year => {
    await processLoading({
      loadingMessage: 'Generando métricas...',
      successfulMessage: 'Métricas generadas correctamente',
      doAction: async ({ token }) => {
        const result = await ruutsApi.metricsProviderService.generateByYear({
          farmId: currentFarm.id,
          year,
          token,
        })

        if (result?.payload?.some(metric => metric.status === 'error')) {
          setConfirmationModalConfig({
            title: 'Error al generar métricas',
            modalType: ModalTypes.ErrorModelType,
            open: true,
            children: (
              <List>
                {result?.payload?.map(metric => (
                  <ListItem key={metric.metricName}>
                    <Typography
                      sx={{ color: metric.status === 'error' ? 'red' : 'green' }}
                      variant="body2"
                    >
                      {metric.metricName}: {metric.status}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            ),
          })
        }
      },
      errorMessage: 'Error generando métricas',
    })
  }

  const handleDownloadMetrics = async year => {
    await processLoading({
      loadingMessage: 'Descargando métricas...',
      successfulMessage: 'Métricas descargadas exitosamente',
      errorMessage: 'Error al descargar métricas, por favor intente nuevamente',
      doAction: async ({ token }) => {
        await ruutsApi.metricsProviderService.getZipByYear({
          farmId: currentFarm.id,
          year,
          token,
        })
      },
    })
  }

  const handleDownloadRawData = async year => {
    await processLoading({
      loadingMessage: 'Descargando Raw Data Collection...',
      successfulMessage: 'Raw Data Collection descargado exitosamente',
      errorMessage: 'Error al descargar Raw Data Collection, por favor intente nuevamente',
      doAction: async ({ token }) => {
        const result = await ruutsApi.getRawDataCollection({
          farmId: currentFarm.id,
          size: 100,
          year,
          token,
        })

        const csv = convertToCSV(result, {
          header: true,
          fields: Object.keys(result?.[0]),
        })

        const fileName = `${currentFarm.name}_${year}_raw_data_${dayjs().format('YYYYMMDDHHmmss')}.csv`
        const mimeType = 'text/csv'
        downloadFile({ fileName, content: csv, mimeType })
      },
    })
  }

  return (
    <Box sx={{ width: '100vw', height: '100%' }}>
      <Box backgroundColor="#cfd8dc" p={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <DataCollectionHomeInfo />
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="20%">
                  AÑO
                </TableCell>
                <TableCell align="center" width="50%">
                  ESTADOS
                </TableCell>
                <TableCell align="center" width="10%">
                  ACCIONES
                </TableCell>
                <TableCell align="center" width="10%" />
                <TableCell align="center" width="10%">
                  DOCUMENTACIÓN COMPLEMENTARIA
                </TableCell>
                <TableCell align="center" width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCollectionStatements?.map(dataCollectionStatement => (
                <TableRow
                  key={dataCollectionStatement.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">
                    {dataCollectionStatement.farmSubdivision.year}
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        display: 'inline-block',
                        borderRadius: 8,
                        py: 1,
                        px: 2,
                        backgroundColor:
                          dataCollectionStatementTypesConventions[
                            dataCollectionStatement.status?.id
                          ].color,
                      }}
                      variant="body2"
                    >
                      {dataCollectionStatement?.status?.es_AR}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <TableActionButtons
                      allowEdit={dataCollectionStatement.actionsAllowed.edit}
                      allowReview={dataCollectionStatement.actionsAllowed.review}
                      allowSend={dataCollectionStatement.actionsAllowed.send}
                      allowView={dataCollectionStatement.actionsAllowed.view}
                      dataCollectionStatement={dataCollectionStatement}
                      editionYear={dataCollectionStatement.farmSubdivision.year}
                      handleEdit={year => handleOpen(year)}
                      handleEditDocuments={recordSelected => handleEditDocuments(recordSelected)}
                      handleReview={handleReview}
                      handleSend={handleSend}
                      handleView={year => handleOpen(year)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableApproveButton
                      allowApprove={dataCollectionStatement.actionsAllowed.approve}
                      dataCollectionStatement={dataCollectionStatement}
                      editionYear={dataCollectionStatement.farmSubdivision.year}
                      handleApprove={statement => handleApprove(statement)}
                      usedYears={usedYears}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableDocumentsButtons
                      handleEditDocuments={recordSelected => handleEditDocuments(recordSelected)}
                      recordData={{
                        farmSubdivisionId: dataCollectionStatement.farmSubdivision.id,
                        year: dataCollectionStatement.farmSubdivision.year,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {currentFarm.programId === PROGRAM_IDS.POA && (
                      <MenuButton>
                        <MenuButton.Button>
                          <MoreVertIcon />
                        </MenuButton.Button>
                        <MenuButton.Item
                          disabled={isViewOnly}
                          onClick={() =>
                            handleGenerateMetrics(dataCollectionStatement.farmSubdivision.year)
                          }
                        >
                          Generar métricas
                        </MenuButton.Item>
                        <MenuButton.Item
                          onClick={() =>
                            handleDownloadMetrics(dataCollectionStatement.farmSubdivision.year)
                          }
                        >
                          Descargar métricas
                        </MenuButton.Item>
                        <MenuButton.Item
                          onClick={() =>
                            handleDownloadRawData(dataCollectionStatement.farmSubdivision.year)
                          }
                        >
                          Descargar raw DC data
                        </MenuButton.Item>
                      </MenuButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {editDocumentsDialogOpen && recordDataSelected && (
          <FarmSubdivisionDocumentsDialog
            farmSubdivisionId={recordDataSelected.farmSubdivisionId}
            handleClose={closeDynamicFarmYearFormDialog}
            open={editDocumentsDialogOpen}
            readOnly={userRoleSelected !== userRoles.FieldTechnician}
            year={recordDataSelected.year}
          />
        )}
      </Box>
    </Box>
  )
}

export default ManagementDataSummary
