export const getDayJsLang = () => {
  const rpdLocale = localStorage?.getItem('lang') || 'es_AR'
  const dayJSLocaleMap = {
    es_AR: 'es',
    en_US: 'en',
    pt_BR: 'pt',
    default: 'es',
  }
  return dayJSLocaleMap[rpdLocale] || dayJSLocaleMap.default
}
