import { useContext, useEffect, useState } from 'react'
import AppContext from '../context/appContext'
import { userRoles } from '../utils/userRoles'

export const useUserRole = () => {
  const { userRoleSelected } = useContext(AppContext)
  const [isViewOnly, setIsViewOnly] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    setIsViewOnly(userRoleSelected === userRoles.Analyst)
    setIsAdmin(userRoleSelected === userRoles.Admin)
  }, [userRoleSelected])

  return { isViewOnly, isAdmin }
}
