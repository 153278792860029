import endpoints from '../../ruutsApi/endpoints'
import getApiData from '../../ruutsApi/getApiData'

export default async function getMonitoringSites(farm, token) {
  try {
    const monitoringSites = await getApiData(
      endpoints.sites,
      {
        farmId: farm.id,
      },
      token,
    )
    return monitoringSites
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get form')
  }
}
