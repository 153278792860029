import React, { useState, useEffect, Fragment } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material'

const EventScopeSelection = ({
  samplingAreas,
  selectedSamplingAreas,
  setSelectedSamplingAreas,
  monitoringSites,
  setSelectedMonitoringSites,
  selectedMonitoringSites,
  onResultChange,
  disabled,
  required,
}) => {
  const [error, setError] = useState(false)
  const [nodes, setNodes] = useState([])
  const [selectedNodes, setSelectedNodes] = useState([])

  useEffect(() => {
    if (selectedSamplingAreas && selectedMonitoringSites) {
      onResultChange({ sa: selectedSamplingAreas, ms: selectedMonitoringSites })
    }
  }, [selectedSamplingAreas, selectedMonitoringSites])

  useEffect(() => {
    setError(!selectedNodes?.length)

    if (!selectedNodes?.length) {
      setSelectedSamplingAreas([])
      setSelectedMonitoringSites([])
    } else {
      const newSelectedMonitoringSites = []
      Object.keys(monitoringSites).forEach(key => {
        monitoringSites[key].forEach(ms => {
          selectedNodes.forEach(sn => {
            if (sn.id === ms.properties.id) {
              newSelectedMonitoringSites.push(ms)
            }
          })
        })
      })

      setSelectedMonitoringSites(newSelectedMonitoringSites)
    }
  }, [selectedNodes])

  useEffect(() => {
    if (samplingAreas && monitoringSites) {
      const newNodes = samplingAreas.features.map(samplingArea => {
        return {
          id: samplingArea.properties.id,
          name: samplingArea.properties.name,
          color: samplingArea.properties.color,
          children: monitoringSites[samplingArea.properties.name].map(ms => ({
            id: ms.properties.id,
            name: ms.properties.name,
          })),
        }
      })
      setNodes(newNodes)
      let allNodes = []
      newNodes.forEach(node => {
        allNodes = [...allNodes, ...node.children]
      })
    }
  }, [samplingAreas, monitoringSites])

  const handleNodeSelection = node => {
    if (selectedNodes.includes(node)) {
      setSelectedNodes(selectedNodes.filter(n => n !== node))
    } else {
      setSelectedNodes([...selectedNodes, node])
    }
  }

  const handleIndeterminateCheck = node => {
    let samplingArea = samplingAreas.features.find(sa => sa.properties.id === node.id)
    if (!node.children) return selectedNodes.includes(node)
    const childrenSelected = node.children.filter(c => selectedNodes.includes(c))
    if (childrenSelected.length === 0) {
      // check if samplingArea exist in selectedSamplingAreas
      if (selectedSamplingAreas.includes(samplingArea)) {
        setSelectedSamplingAreas(selectedSamplingAreas.filter(sa => sa !== samplingArea))
      }
      return false
    }

    samplingArea = samplingAreas.features.find(sa => sa.properties.id === node.id)
    if (!selectedSamplingAreas.includes(samplingArea)) {
      setSelectedSamplingAreas([...selectedSamplingAreas, samplingArea])
    }
    if (childrenSelected.length === node.children.length) return true
    return 'indeterminate'
  }

  const handleParentSelection = node => {
    let allChildrenSelected = true
    if (!node.children) return
    node.children.forEach(c => {
      if (!selectedNodes.includes(c)) {
        allChildrenSelected = false
      }
    })
    if (allChildrenSelected) {
      setSelectedNodes(selectedNodes.filter(n => !node.children.includes(n)))
    } else {
      setSelectedNodes([...selectedNodes, ...node.children])
    }
  }

  return (
    <>
      <SimpleTreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ margin: 2 }}
      >
        {nodes.length > 0 &&
          nodes.map(node => (
            <TreeItem
              key={node.id}
              itemId={node.id.toString()}
              label={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={handleIndeterminateCheck(node) === true}
                      indeterminate={handleIndeterminateCheck(node) === 'indeterminate'}
                      sx={{
                        color: node.color,
                        '&.Mui-checked': {
                          color: node.color,
                          opacity: 0.7,
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: node.color,
                          opacity: 0.7,
                        },
                      }}
                      onChange={() => handleParentSelection(node)}
                    />
                  }
                  disabled={disabled}
                  label={
                    <Typography sx={{ fontSize: '1rem' }} variant="body1">
                      {node.name}
                    </Typography>
                  }
                  onClick={e => e.stopPropagation()}
                />
              }
            >
              {node.children &&
                node.children.map(child => (
                  <TreeItem
                    key={child.id}
                    itemId={child.id.toString()}
                    label={
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedNodes.includes(child)}
                            defaultChecked={false}
                            disabled={disabled}
                            sx={{
                              // node.color is hsl, make it smoother
                              color: node.color,
                              '&.Mui-checked': {
                                color: node.color,
                              },
                              opacity: 0.4,
                            }}
                            onChange={() => handleNodeSelection(child)}
                          />
                        }
                        disabled={disabled}
                        label={child.name}
                      />
                    }
                  />
                ))}
            </TreeItem>
          ))}
      </SimpleTreeView>
      {!disabled && required && error && (
        <Typography color="error">Debe seleccionar al menos una opción</Typography>
      )}
    </>
  )
}

export default EventScopeSelection
