import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useUserRole } from '../../hooks/useUserRole'
import getMonitoringSites from '../../services/farmMapping/monitoringSites/getMonitoringSites'
import { validatePerimeterPreconditions } from '../../services/farmMapping/updatePerimeter'
import { ruutsApi } from '../../services/ruutsApi/index'
import { processValidation } from '../../utils/Errors/validations'
import { contactSupportReasons } from '../GlobalLoadingModal/GlobalLoadingModal'
import PerimeterMap from './PerimeterMap'
import PerimeterValidationPanel from './PerimeterValidationPanel'

const PerimeterHome = ({ farm, mapWidth, mapHeight }) => {
  const [mapReady, setMapReady] = useState(false)
  const [mapActions, setMapActions] = useState(null)
  const [features, setFeatures] = useState(null)
  const [_hasPerimeter, setHasPerimeter] = useState(null)
  const [featuresUpdated, setFeaturesUpdated] = useState(null)
  const [isPerimeterValidated, setIsPerimeterValidated] = useState(null)
  const { user, getAccessTokenSilently } = useAuth0()
  const [canValidate, setCanValidate] = useState(null)
  const [overrideEdition, setOverrideEdition] = useState(null)
  const [highlightedPoint, setHighlightedPoint] = useState(null)
  const { isViewOnly, isAdmin } = useUserRole()

  useEffect(() => {
    setIsPerimeterValidated(null)
    setOverrideEdition(isAdmin)
  }, [isAdmin])

  useEffect(() => {
    // The isPerimeterValidated check is to avoid rerendering the tab when there is no change in the farm perimeter validation
    // Otherwise, the map would be reloaded and the user would lose the changes made in the map since the validation points
    // are reloaded from the database.
    if (!farm || farm.isPerimeterValidated === isPerimeterValidated) return

    const loadExtraData = async () => {
      const token = await getAccessTokenSilently()
      const updateFarm = await ruutsApi.getFarm({ id: farm.id, user, token })

      const perimeter = updateFarm.toGeoJSON
      const newPerimeter = {
        ...perimeter,
        properties: {
          ...perimeter.properties,
          type: 'perimeter',
          originalGeometry: perimeter.geometry,
        },
        style: { ...perimeter.style, color: 'green' },
      }

      const monitorSites = await getMonitoringSites(updateFarm, token)

      const validationMonitorSites = monitorSites
        .filter(site => site.isValidationSite)
        .map(validationSite => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [validationSite.location[1], validationSite.location[0]],
            },
            properties: {
              id: validationSite.id,
              name: validationSite.name,
            },
            style: { color: 'green' },
          }
        })
      const newFeatures = [newPerimeter, ...validationMonitorSites].sort((a, b) =>
        a.properties.name.localeCompare(b.properties.name),
      )

      setIsPerimeterValidated(updateFarm.isPerimeterValidated)
      setHasPerimeter(!updateFarm.geometry)
      setFeaturesUpdated(newFeatures)
      setFeatures(newFeatures)

      if (!isViewOnly) {
        const newCanValidate = await processValidation({
          contactSupportReasonType: contactSupportReasons.PerimeterValidationsPreconditionFailed,
          getToken: getAccessTokenSilently,
          // eslint-disable-next-line no-shadow
          doAction: async ({ token }) => {
            return validatePerimeterPreconditions({ id: updateFarm.id, overrideEdition, token })
          },
        })
        setCanValidate(newCanValidate)
      } else {
        setCanValidate(false)
      }
    }

    loadExtraData()
  }, [farm, getAccessTokenSilently, isPerimeterValidated, isViewOnly, overrideEdition, user])

  return (
    features &&
    isPerimeterValidated !== null && (
      <Grid container>
        <Grid item xs={4}>
          <PerimeterValidationPanel
            canValidate={canValidate}
            featuresUpdated={featuresUpdated}
            isPerimeterValidated={isPerimeterValidated}
            mapReady={mapReady}
            overrideEdition={overrideEdition}
            setFeatures={setFeatures}
            setHighlightedPoint={setHighlightedPoint}
            setMapActions={setMapActions}
          />
        </Grid>
        <Grid item xs={8}>
          <PerimeterMap
            features={features}
            highlightedPoint={highlightedPoint}
            mapActions={mapActions}
            mapHeight={mapHeight}
            mapWidth={mapWidth}
            setFeaturesUpdated={setFeaturesUpdated}
            setMapReady={setMapReady}
          />
        </Grid>
      </Grid>
    )
  )
}

export default PerimeterHome
